.storymap-header {
    background: #fff;
    left: 0;
    padding: 70px 1rem 0.5rem 1rem;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
}

.storymap-actions {
    display: flex;
    font-size: 1rem;
    position: absolute;
    top: 0;
    right: 0;

    .storymap-action {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0 1rem;

        &:last-of-type {
            padding-right: 0;
        }
        
        & + .storymap-action {
            border-left: 1px dashed #ccc;
        }
    }

    label {
        margin: 0 0.5rem 0 0;
    }
}

.storymap-container {
    margin-top: calc(70px + 3rem);
}

.storymap-release {
    border: none;
    border-top: 1px dashed #e5e5e5;
    min-width: max-content;
    position: relative;
    transition: all 0.3s ease;

    legend {
        font-size: 1rem;
        margin: 0 0 0 1.5rem;
        padding: 0 0.5rem;
        width: auto;
    }
}

.storymap-goal-collapse {
    align-items: center;
    display: flex;
    font-size: 2rem;
    flex-direction: row;
    min-width: 1.875rem;
}

.storymap-goal-collapse-hint {
    font-size: 1rem;
    font-weight: lighter;
    opacity: 0;
    transition: opacity 0.3s linear;
}

.storymap-goal:hover {
    .storymap-goal-collapse-hint {
        opacity: 1;
    }
}

.storymap-collapse {
    margin-right: 0.5rem;
    transition: transform 0.5s linear;
    transform: rotate(540deg);
}

.storymap-collapsed {
    transform: rotate(0deg);
}

.storymap-actions-container {
    margin-top: 0.5rem;
    min-height: 80px;
    min-width: 150px;
    opacity: 0;
    padding-left: 1rem;
    transition: opacity 0.3s linear;
    width: 150px;
}

.storymap-release:hover {
    .storymap-actions-container {
        opacity: 1;
    }
}

.storymap-release-target {
    border: 1px dashed #e5e5e5;
}

.storymap-card {
    min-height: 80px;
    margin: 0.5rem;
    padding: 0.5rem;
    position: relative;
    width: 200px;
    transition: all 0.3s ease;
}

.placeholder-row {
    // Includes icon width and relevant margins
    margin-right: 1.875rem;
}

.storymap-card-create {
    align-items: center;
    border: 1px dashed #ccc;
    display: flex;
    font-size: 2rem;
    justify-content: center;
    margin: 0.5rem;
    padding: 0.5rem;
}

.storymap-card-sm-for-column {
    height: 3rem;
    margin-top: 0rem;
    min-height: 3rem;
    min-width: 200px;
    width: 200px;
}

.storymap-card-sm-for-row {
    height: 80px;
    margin-left: 0rem;
    min-height: 80px;
    min-width: 3rem;
    width: 3rem;
}

.storymap-card-actions {
    align-items: center;
    border: 1px dashed #ccc;
    border-bottom-left-radius: 0.25rem;
    border-right: none;
    border-top-left-radius: 0.25rem;
    display: none;
    flex-direction: column;
    height: 4rem;
    justify-content: center;
    margin: auto;
    padding: 0.5rem;    
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 2rem;
    transition: opacity 0.3s linear;
}

.storymap-card-actionable:hover {
    .storymap-card-actions {
        background: #fff;
        display: flex;
        z-index: 100;
    }
}

.storymap-card-editor {

    & > div {
        flex: 2;
        padding: 0 1rem 1rem 0;
    }

    & > div + div {
        flex: 1;
        padding: 0 0 1rem 1rem;
    }

    .form-row {
        margin: 0;
    }

    .form-row + .form-row {
        margin-top: 0.5rem;
    }

    input {
        border-top-right-radius: .25rem !important;
        border-bottom-right-radius: .25rem !important;
    }
}

.storymap-label-selector {
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    width: 2.5rem;
}

.storymap-label-edit {
    align-items: center;
    color: #444;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 0.5rem;
    padding-right: 0;
    width: 1.75rem;

    &:visited {
        color: #444;
    }

    &:active,
    &:hover,
    &:focus {
        color: #007bff;
    }    
}

.storymap-label-pill {
    border-radius: 1rem;
    height: 0.5rem;
    margin: 0 0.25rem;
    width: 3rem;

    &:first-child {
        margin-left: 0;
    }
}