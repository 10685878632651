.modal {
    background: #fff;
    box-shadow: 0 0 1em #000;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-height: 100vh;
    overflow: auto;
    padding: 1.5rem;
    position: absolute;
    right: 0;
    top: -100vh;
    transition: all 0.3s;
    max-width: 50vw;
    z-index: 2001; // a space odyssey
}

.modal-open {
    top: 0;
}

.modal-small {
    width: 350px;
}