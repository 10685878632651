@import "bootstrap-overrides";
@import "flyout";
@import "loading";
@import "modal";
@import "storymap";
@import "toggle";

html,
body {
    font-size: 14px;
}

/* -- Margin for fixed navbar -- */
.app-body {
    margin-top: 70px;
}

/* -- Margin for iconified menu --*/
.dropdown-item svg, .nav-link svg {
    width: 16px !important;
    margin-right: 0.5rem;
}

/* -- Home page layout -- */
.landing-page {
    overflow: hidden;

    .logo {
        color: #28a745;
        font-family: monospace;
        font-size: 8rem;
        text-shadow: 2px 2px 2px green;
    }
}

/* -- Utilities -- */
.clickable {
    cursor: pointer;
}

.delinkify,
.delinkify:visited {
    color: initial;
    text-decoration: none !important;
}

.delinkify:active,
.delinkify:focus,
.delinkify:hover {
    color: #007bff;
}

.fa-action {
    display: block;
    opacity: 0.75;
}

.fa-action:hover {
    opacity: 1;
}

.card-fade {
    border-style: dashed;
    opacity: 0.8;
}

.card-fade:hover {
    opacity: 1;
}

.card-closed {
    background: #ddd;
}

.btn-circle {
    border-radius: 50%;
    height: 35px;
    line-height: 2.3rem;
    padding: 0;
    text-align: center;
    width: 35px;
    position: relative;

    &::after {
        border-radius: 50%;
        box-shadow: 0 0 10px #ccc;
        content: '';
        height: 34px;
        opacity: 0;
        position: absolute;
        top: 0; left: 0;
        transition: opacity 0.3s ease-in-out;
        width: 34px;
        z-index: -1;
    }

    &:hover {
        &::after {
            opacity: 1;
        }
    }
}

.card-square {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-weight: lighter;
    height: 12rem;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    width: 12rem;

    & ~ .card-square {
        margin-left: 2rem;
    }

    &::after {
        box-shadow: 0 0 10px #ccc;
        content: '';
        height: 100%;
        opacity: 0;
        position: absolute;
        transition: opacity 0.3s ease-in-out;
        width: 100%;
        z-index: -1;
    }

    &:hover {
        color: #007bff;

        &::after {
            opacity: 1;
        }
    }
}

nav {
    box-shadow: 1px 0 0.5rem #ccc;
}

nav.is-busy {
    animation: party 4s infinite;
    animation-direction: alternate;
}

@keyframes party {
    0% {
        box-shadow: 1px 0 0.5rem #007bff;
    }
    33% {
        box-shadow: 1px 0 0.5rem #17a2b8;
    }
    66% {
        box-shadow: 1px 0 0.5rem #28a745;
    }
    100% {
        box-shadow: 1px 0 0.5rem #ffc107;
    }
}