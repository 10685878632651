.flyout {
    background: #fff;
    bottom: 0;
    box-shadow: 0 2px 1rem #000;
    display: block;
    height: 100vh;
    margin: auto;
    min-width: 20rem;
    max-width: 480px;
    overflow: auto;
    padding: 1.5rem 1rem;
    position: fixed;
    top: 0;
    transition: transform 0.3s linear;
    transform: translateX(-100%);
    z-index: 2000;

    h4 {
        margin-right: 1rem;
        width: 100%;
    }
}

.flyout-open {
    transform: translateX(0);
}

.flyout-close {
    font-size: 1.25rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
}